.dropdown-toggle::after {
    display: none !important; 
  }

  .dropdown-toggle::before {
    display: none !important; 
  }

  .nav-item .dropdown-item:hover, .nav-item .dropdown-item:focus {
    background-color: #CCF0FF !important;
  }

  .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
    background-color: #CCF0FF !important;
}
